import { Tlt, useDispatch, useStore } from "@clairejs/react";
import { cilAccountLogout, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Avatar } from "antd";
import { useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import { useNavigator } from "@clairejs/react-web";
import {
    CDropdown,
    CDropdownDivider,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
} from "@coreui/react";

import { DataStore } from "../../../store/store";

import { sessionActions } from "../../../store/action/session";

import ProfileSettings from "../../common/user-settings/profile-settings";

const AppHeaderDropdown = () => {
    const user = useStore(DataStore, (s) => s.me?.info);
    const navigator = useNavigator();
    const dispatch = useDispatch(DataStore);

    const [showUpdateProfile, setShowUpdateProfile] = useState(false);

    const logout = async () => {
        await dispatch(sessionActions.logout());
        navigator.navigate("/");
    };

    return (
        <>
            <CDropdown variant="nav-item">
                <CDropdownToggle className="py-0 pe-0" caret={false}>
                    <Avatar icon={<UserOutlined />} src={user?.avatar} size={40} />
                </CDropdownToggle>

                <CDropdownMenu className="pt-0">
                    <CDropdownHeader className="bg-body-secondary fw-semibold mb-2">
                        <Tlt template="Account" />
                    </CDropdownHeader>
                    <CDropdownItem href="#" onClick={() => setShowUpdateProfile(true)}>
                        <CIcon icon={cilUser} className="me-2" />
                        <Tlt template="Update profile" />
                    </CDropdownItem>

                    {/* <CDropdownHeader className="bg-body-secondary fw-semibold my-2">Settings</CDropdownHeader>
                    <CDropdownItem href="#">
                        <CIcon icon={cilUser} className="me-2" />
                        <Tlt template="Link social accounts" />
                    </CDropdownItem>

                    <CDropdownItem href="#">
                        <CIcon icon={cilUser} className="me-2" />
                        <Tlt template="Setup 2FA" />
                    </CDropdownItem> */}

                    <CDropdownDivider />
                    <CDropdownItem href="#" onClick={logout}>
                        <CIcon icon={cilAccountLogout} className="me-2" />
                        <Tlt template="Sign out" />
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
            {showUpdateProfile && <ProfileSettings onClose={() => setShowUpdateProfile(false)} />}
        </>
    );
};

export default AppHeaderDropdown;
