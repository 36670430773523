import { IonIcon } from "@ionic/react";
import { logoFacebook, logoTwitter, mail } from "ionicons/icons";
import styled from "styled-components";
import { useState } from "react";
import { getText, Tlt } from "@clairejs/react";
import { Link, useNavigator } from "@clairejs/react-web";

import { facebookLogoColor, MutedBorderColor, MutedTextColor, TextColor } from "../../utils/style";

import logo from "../../assets/images/logo.svg";

import LanguageSwitcher from "../../components/language-switcher";

function SocialIcon(props: { icon: any; focusColor: string; link: string }) {
    const [hover, setHover] = useState(false);

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                color: hover ? props.focusColor : MutedTextColor,
                display: "flex",
                alignItems: "center",
            }}>
            <IonIcon
                onClick={() => props.link && window.open(props.link, "_blank")}
                className="hover-icon"
                style={{ fontSize: "20px" }}
                icon={props.icon}
            />
        </div>
    );
}

function MenuLink(props: { path: string; text: string }) {
    const navigator = useNavigator();

    const isActive = (path: string) => {
        const currentPath = navigator.getPath();

        return path === currentPath || (path !== "/" && currentPath.startsWith(path));
    };

    return (
        <Link
            style={{ textDecoration: "none" }}
            key={props.path}
            className={`menu-link ${isActive(props.path) ? "active" : ""}`}
            to={props.path}>
            <span className="link-text">{props.text}</span>
        </Link>
    );
}

function MenuGroup(props: { title: string; menuItems: { title: string; link: string }[] }) {
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: 5,
            }}>
            <div style={{ fontWeight: "bold", color: TextColor }}>{props.title}</div>
            <div
                style={{
                    color: MutedTextColor,
                    fontSize: 14,
                    display: "flex",
                    flexDirection: "column",
                }}>
                {props.menuItems.map((item) => (
                    <MenuLink key={item.title} text={item.title} path={item.link} />
                ))}
            </div>
        </div>
    );
}

const Wrapper = styled.div`
    margin-top: 21px;

    .hover-icon {
        transition: color 0.2s linear; /* Smooth transition */
    }

    .hover-icon:hover {
    }

    .divider {
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, transparent, ${MutedBorderColor}, transparent);
        border: none;
    }

    .menu-link {
        color: ${MutedTextColor} !important;
        padding: 3px 0;
        cursor: pointer;
    }

    .menu-link:hover {
        color: ${TextColor} !important;
    }

    .left-footer {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .right-footer {
        flex: 1;
        display: flex;
        gap: 21px;
    }
`;

export default function Footer() {
    return (
        <Wrapper className="div-wrapper">
            <div
                style={{
                    padding: "35px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 35,
                    flexWrap: "wrap",
                }}>
                <div className="left-footer">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                        }}>
                        <img
                            alt="logo"
                            src={logo}
                            style={{
                                width: 30,
                                height: 30,
                                objectFit: "contain",
                            }}
                        />
                        <div
                            style={{
                                fontWeight: "bold",
                                color: "#666666",
                                fontSize: 18,
                                marginTop: 3,
                            }}>
                            Free
                        </div>
                    </div>
                    <div
                        style={{
                            fontSize: 13,
                            color: MutedTextColor,
                            marginTop: 8,
                            whiteSpace: "pre",
                        }}>
                        <Tlt id="app_intro" />
                    </div>
                    <div style={{ marginTop: 21 }}>
                        <LanguageSwitcher />
                    </div>
                </div>
                <div className="right-footer">
                    <MenuGroup
                        title={getText(<Tlt template="Programs" />)}
                        menuItems={[
                            {
                                title: getText(<Tlt template="Referral Program" />),
                                link: "/programs/referral",
                            },
                        ]}
                    />
                    <MenuGroup
                        title={getText(<Tlt template="Compliance" />)}
                        menuItems={[
                            {
                                title: getText(<Tlt template="Terms of Use" />),
                                link: "/terms",
                            },
                            {
                                title: getText(<Tlt template="Privacy Policy" />),
                                link: "/privacy",
                            },
                        ]}
                    />
                    <MenuGroup
                        title={getText(<Tlt template="Resources" />)}
                        menuItems={[
                            {
                                title: getText(<Tlt template="Help Center" />),
                                link: "/help",
                            },
                            // {
                            //     title: getText(<Tlt template="Contact Us" />),
                            //     link: "/contact",
                            // },
                        ]}
                    />
                </div>
            </div>
            <div className="divider" />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "13px 0",
                    gap: 13,
                }}>
                <div style={{ fontSize: 12, color: MutedTextColor }}>
                    <Tlt id="copy_right" />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 21,
                    }}>
                    <SocialIcon
                        icon={logoFacebook}
                        focusColor={facebookLogoColor}
                        link="https://www.facebook.com/freeservicemap"
                    />
                    {/* <SocialIcon icon={logoTwitter} focusColor={"#1DA1F2"} link="" /> */}
                    <SocialIcon icon={mail} focusColor={"#F29D1D"} link="mailto:contact@yesfree.app" />
                </div>
            </div>
        </Wrapper>
    );
}
