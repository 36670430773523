import {
    AbstractModel,
    Column,
    Model,
    FK,
    Mime,
    DataType,
    ElementDto,
    Data,
    Field,
    Enum,
    HttpMethod,
    Index,
} from "@clairejs/core";

import { User, GeoPlace } from "./user";
import { Service } from "./service";
import { ServiceProfile } from "./service-profile";
import { VendorReply } from "./vendor-reply";

export enum SearchStatus {
    SEARCHING = "searching", // waiting for replies
    CONFIRMED = "confirmed", // vendor selected
    CLOSED = "closed", // search closed without any service confirm
    CANCELLED = "cancelled", // search cancelled by vendor
    SERVED = "served", // vendor served the request
    RATED = "rated", // vendor served the request
}

export enum SearchCloseReason {
    user_requested = "user_requested",
    search_expired = "search_expired",
}

@Data()
export class VendorInfo {
    @Field({
        isRequired: true,
    })
    dailyCounter!: number;

    @Field({
        ...FK({ model: ServiceProfile }),
        isRequired: true,
    })
    profileId!: string;

    @Field({
        ...FK({ model: User }),
        isRequired: true,
    })
    vendorId!: string;

    @Field({
        isRequired: true,
        floating: true,
    })
    distance!: number;

    @Field({
        description: "The rating of service profile",
        floating: true,
    })
    rating?: number;
}

@Model({
    ignoreCrud: [HttpMethod.POST],
})
@Index([["vendors.vendorId", 1]])
@Index([["status", 1]])
@Index([["rejectedVendorIds", 1]])
@Index([["userId", 1]])
@Index([["vendor", 1]])
@Index([["createdAt", 1]])
@Index([["location", "2dsphere"]])
export class Search extends AbstractModel {
    @Column({
        isRequired: true,
        serverValue: true,
        ...FK({ model: User }),
    })
    userId!: string;

    @Column({
        isRequired: true,
        ...FK({ model: Service, cascade: "delete" }),
    })
    serviceId!: string;

    @Column({
        isRequired: true,
        description: "Name of the region",
        serverValue: true,
    })
    region!: string;

    @Field({
        isRequired: true,
        ...Enum(SearchStatus),
    })
    status!: SearchStatus;

    @Column({
        description: "When the search become available",
        isRequired: true,
        isTimestamp: true,
    })
    expiredAt!: number;

    @Column({
        description: "When the search is fulfilled",
        isTimestamp: true,
    })
    finishedAt?: number;

    @Column({
        description: "The serving location",
        isRequired: true,
    })
    location!: GeoPlace;

    @Column({
        description: "Price end range",
    })
    priceTo?: string;

    @Column({
        description: "Ask currency",
        isRequired: true,
    })
    currency!: string;

    @Column({
        description: "Distance in meters to search for vendors",
        isRequired: true,
        rangeProps: { min: 0 },
    })
    searchRange!: number;

    @Column({
        vectorProps: { elementDataType: DataType.STRING },
        mimeProps: { public: true, type: Mime.IMAGE, maxFileKB: 1024 },
    })
    searchImages?: string[];

    @Column({
        description: "User's note for vendor",
        textLength: 512,
    })
    userNote?: string;

    @Column({
        description: "The reason why the search is closed",
        dataType: DataType.STRING,
    })
    closeReason?: SearchCloseReason | string;

    @Column({
        description: "Replies of vendor",
        vectorProps: { elementDataType: DataType.OBJECT },
        serverValue: true,
        ...ElementDto(VendorReply),
    })
    replies: VendorReply[] = [];

    @Column({
        description: "Ids of vendors",
        isRequired: true,
        serverValue: true,
        vectorProps: { elementDataType: DataType.OBJECT },
        ...ElementDto(VendorInfo),
    })
    vendors!: VendorInfo[];

    @Column({
        description: "Ids of rejected vendors",
        serverValue: true,
        vectorProps: { elementDataType: DataType.STRING },
        ...FK({ model: User }),
    })
    rejectedVendorIds?: string[];

    @Column({
        description: "Count of ids of vendors",
        serverValue: true,
        isRequired: true,
    })
    vendorCount!: number;

    @Field({
        isRequired: true,
        serverValue: true,
        description: "Amount of payment user had paid",
    })
    cost!: number;

    @Field({
        description: "The chosen vendor",
        ...FK({ model: User }),
    })
    vendor?: string;

    @Field({
        rangeProps: { min: 0, max: 3 },
    })
    rating?: number;

    @Column({
        vectorProps: { elementDataType: DataType.STRING },
        mimeProps: { public: true, type: Mime.IMAGE, maxFileKB: 1024 },
    })
    vendorImages?: string[];

    @Column({
        description: "Whether this search is actively waiting for available vendors",
    })
    waiting?: boolean;

    @Field({
        serverValue: true,
        description: "Amount of coin shared to referer",
    })
    referalShare?: number;
}
