import CIcon from "@coreui/icons-react";
import {
    cilBank,
    cilBullhorn,
    cilCode,
    cilCog,
    cilFastfood,
    cilGlobeAlt,
    cilMoney,
    cilPeople,
    cilSearch,
    cilSettings,
    cilSpeedometer,
    cilTransfer,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { Tlt } from "@clairejs/react";

export interface MenuItem {
    component?: any;
    name: any;
    icon?: any;
    to?: string;
    badge?: { text: string; color: string };
    indent?: boolean;
    items?: MenuItem[];
    label?: any;
    permissions?: string[];
}

const _nav: MenuItem[] = [
    {
        component: CNavItem,
        name: "Dashboard",
        label: <Tlt template="Dashboard" />,
        to: "/admin/dashboard",
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
        component: CNavTitle,
        name: "REFERRAL",
        label: <Tlt template="REFERRAL" />,
    },
    {
        component: CNavItem,
        name: "Revenue",
        label: <Tlt template="Revenue" />,
        to: "/admin/referal/revenue",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    },
    {
        component: CNavItem,
        name: "Settings",
        label: <Tlt template="Settings" />,
        to: "/admin/referal/code",
        icon: <CIcon icon={cilCode} customClassName="nav-icon" />,
    },
    {
        component: CNavItem,
        name: "Users",
        to: "/admin/referal/users",
        label: <Tlt template="Users" />,
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    },
    {
        component: CNavTitle,
        name: "SEARCHES",
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Searches",
        to: "/admin/searches",
        icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Configs",
        to: "/admin/search-configs",
        icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavTitle,
        name: "PAYMENT",
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Cashouts",
        to: "/admin/payments/cashouts",
        icon: <CIcon icon={cilTransfer} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Payments",
        to: "/admin/payments/list",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavGroup,
        name: "Accounts",
        icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
        permissions: [],
        items: [
            {
                component: CNavItem,
                name: "Accounts",
                to: "/admin/payments/accounts",
            },
            {
                component: CNavItem,
                name: "Currencies",
                to: "/admin/payments/currencies",
            },
        ],
    },
    {
        component: CNavTitle,
        name: "DATA",
        permissions: [],
    },
    {
        component: CNavGroup,
        name: "Users",
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
        permissions: [],
        items: [
            {
                component: CNavItem,
                name: "Users list",
                to: "/admin/data/users/list",
            },
            {
                component: CNavItem,
                name: "Users map",
                to: "/admin/data/users/map",
            },
        ],
    },
    {
        component: CNavItem,
        name: "Services",
        to: "/admin/data/services",
        icon: <CIcon icon={cilFastfood} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Countries",
        to: "/admin/data/countries",
        icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavTitle,
        name: "SYSTEM",
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Announcements",
        to: "/admin/system/announces",
        icon: <CIcon icon={cilBullhorn} customClassName="nav-icon" />,
        permissions: [],
    },
    {
        component: CNavItem,
        name: "Settings",
        to: "/admin/system/settings",
        icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
        permissions: [],
    },
];

export default _nav;
