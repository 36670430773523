import { getText, Tlt, useAPI } from "@clairejs/react";
import { Button, Checkbox, Divider, Form, Input, Modal, Spin } from "antd";
import { useEffect, useState } from "react";

import { CreateSessionBody, CreateSessionResponse, IdentityProvider } from "../../../dto/http/user";

import { UserApi } from "../../../store/api/user";

import { MutedBorderColor, MutedTextColor, RedColor } from "../../../utils/style";

import OtpInput from "../../../components/otp-input";
import FacebookLoginButton from "./facebook-login";
import AppleLoginButton from "./apple-login";

const googleRecaptchSiteKey = "6LfiVOYqAAAAANTQ0rnM2nMlnU5ZmFncJlp24IHc";

const useCaptcha = () => {
    const w = window as any;

    const [executed, setExecuted] = useState(false);
    const [progress, setProgress] = useState(false);

    const execute = () => {
        setExecuted(false);

        if (executed) {
            w.grecaptcha.reset();
        }

        return new Promise<string>((resolve) => {
            w.grecaptcha.ready(() => {
                setProgress(true);

                try {
                    const id = w.grecaptcha.render("recaptcha", {
                        sitekey: googleRecaptchSiteKey,
                        size: "invisible",
                        callback: (response: string) => {
                            setProgress(false);
                            setExecuted(true);
                            resolve(response);
                        },
                    });

                    w.grecaptcha.execute(id);
                } catch (err) {
                    //-- already render then ignore
                }
            });
        });
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return { execute, progress, executed };
};

function GoogleCaptchaCheckbox({ progress, executed }: { progress?: boolean; executed?: boolean }) {
    return (
        <div>
            {progress ? (
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <Spin />
                    <span style={{ color: MutedTextColor }}>
                        <Tlt template="Checking..." />
                    </span>
                </div>
            ) : executed ? (
                <Checkbox checked={true} disabled={true}>
                    <Tlt template="I'm not a robot" />
                </Checkbox>
            ) : null}
            <div id="recaptcha"></div>
        </div>
    );
}

interface Props {
    disabled?: boolean;
    hasCaptcha?: boolean;
    handleResponse?: (data: CreateSessionResponse & { email?: string }) => Promise<void>;
    submitButtonText?: string;
    extraQuery?: Record<string, string>;
}
export default function CredentialForm({ hasCaptcha, submitButtonText, handleResponse, extraQuery, ...props }: Props) {
    const { execute, ...captchaProps } = useCaptcha();
    const api = useAPI(UserApi);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtp, setShowOtp] = useState(false);
    const [progress, setProgress] = useState(false);
    const [otpError, setOtpError] = useState(false);

    const handleCredential = async (data: CreateSessionBody) => {
        try {
            setProgress(true);

            const response = await api.createSession(data, extraQuery);

            if (response) {
                if (response.mustVerify) {
                    //-- show otp input
                    setShowOtp(true);
                } else {
                    await handleResponse?.({ ...response, email: data.data[0] });
                }
            }
        } finally {
            setProgress(false);
        }
    };

    const verifyOtp = async () => {
        try {
            setProgress(true);
            setOtpError(false);

            await api.performResetPassword({
                email,
                otp,
                secret: password,
            });

            //-- authen with password again
            await handleEmailPassword(true);
        } catch (err) {
            setOtpError(true);
        } finally {
            setProgress(false);
        }
    };

    const handleEmailPassword = async (skipCaptcha = false) => {
        try {
            setProgress(true);
            //-- trigger captcha
            const token = hasCaptcha && !skipCaptcha ? await execute() : undefined;

            await handleCredential({
                provider: IdentityProvider.EMAIL,
                data: [email, password, ...(token ? [token] : [])],
            });
        } finally {
            setProgress(false);
        }
    };

    const disabled = progress || props.disabled || captchaProps.progress || showOtp;

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 13,
                    flexWrap: "wrap",
                }}>
                <FacebookLoginButton
                    disabled={disabled}
                    onLogin={(err, token) => {
                        if (token) {
                            handleCredential?.({
                                provider: IdentityProvider.FACEBOOK,
                                data: [token.token],
                            });
                        }
                    }}
                />
                <AppleLoginButton
                    disabled={disabled}
                    onLogin={(err, token) => {
                        if (token) {
                            handleCredential?.({
                                provider: IdentityProvider.APPLE,
                                data: [token.token],
                            });
                        }
                    }}
                />
            </div>
            <Divider orientation="center">
                <div style={{ color: MutedBorderColor }}>
                    <Tlt template="or" />
                </div>
            </Divider>
            <div>
                <Form layout="vertical" disabled={disabled}>
                    <Form.Item label={<Tlt template="Email address" />}>
                        <Input onChange={(e) => setEmail(e.target.value)} size="large" type="email" />
                    </Form.Item>
                    <Form.Item label={<Tlt template="Password" />}>
                        <Input.Password onChange={(e) => setPassword(e.target.value)} size="large" />
                    </Form.Item>
                </Form>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 21,
                }}>
                <GoogleCaptchaCheckbox {...captchaProps} />
                <Button
                    style={{ flex: 1, maxWidth: 200 }}
                    disabled={!email || !password || disabled}
                    loading={progress || captchaProps.progress}
                    size="large"
                    type="primary"
                    onClick={() => handleEmailPassword()}>
                    {submitButtonText}
                </Button>
            </div>

            {showOtp && (
                <Modal
                    title={getText(<Tlt template="Verify your account" />)}
                    open={true}
                    maskClosable={false}
                    onOk={verifyOtp}
                    okButtonProps={{ loading: progress, disabled: otp.length !== 6 }}
                    onCancel={() => setShowOtp(false)}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 13,
                            padding: 21,
                        }}>
                        <div>
                            <Tlt template="Please input the verification code that we sent to your email." />
                        </div>
                        <OtpInput
                            disabled={progress}
                            onChange={(otp) => {
                                setOtp(otp);
                                setOtpError(false);
                            }}
                            otpLength={6}
                        />

                        <div style={{ color: RedColor }}>
                            {otpError && <Tlt template="The verification code is incorrect." />}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
