import { Outlet } from "@clairejs/react-web";
import styled from "styled-components";
import { useLanguage } from "@clairejs/react";

import CustomSuspense from "../../components/custom-suspense";
import Header from "./header";
import Footer from "./footer";

const Wrapper = styled.div`
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .div-wrapper {
        width: 100%;
        max-width: 1024px;
        padding: 0px 55px;
    }

    .head {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: 34px;
            object-fit: contain;
        }
    }

    .main-content {
        flex: 1;
    }

    @media screen and (max-width: 480px) {
        .div-wrapper {
            padding: 0px 34px;
        }
    }
`;

function Home() {
    useLanguage();

    return (
        <Wrapper>
            <Header />
            <div className="div-wrapper main-content">
                <CustomSuspense>
                    <Outlet />
                </CustomSuspense>
            </div>
            <Footer />
        </Wrapper>
    );
}

export default Home;
