import { Config } from "./config";

const config: Config = {
    API_SERVER_URL: "https://api.alovendor.tamidi.com",
    STRIPE_DASHBOARD_URL: "https://dashboard.stripe.com",
    WEB_SIGNUP_LINK: "https://yesfree.app/signup",
    FIREBASE_APP: {
        apiKey: "AIzaSyBpnLfV1Ow7uF-U8ICul48FUrn58Df2BxE",
        authDomain: "alovendor-6c146.firebaseapp.com",
        projectId: "alovendor-6c146",
        storageBucket: "alovendor-6c146.firebasestorage.app",
        messagingSenderId: "593539572301",
        appId: "1:593539572301:web:84d5703613832790803204",
        measurementId: "G-80JJ64QQYX"
    },
    SUPPORT_CHAT_LINK: "https://m.me/316878261515934",
};

export default config;
