import { AbstractModel, Column, Model, FK } from "@clairejs/core";

import { User } from "./user";

@Model()
export class UserVoice extends AbstractModel {
    @Column({
        ...FK({ model: User, cascade: "delete" }),
        isRequired: true,
    })
    userId!: string;

    @Column({
        isRequired: true,
        textLength: 100,
    })
    title!: string;

    @Column({
        isRequired: true,
        textLength: 200,
    })
    content!: string;

    @Column({
        ...FK({ model: User, cascade: "delete" }),
    })
    checkedBy?: string;
}
