import { Link, useNavigator } from "@clairejs/react-web";
import { getText, Tlt, useDispatch, useStore } from "@clairejs/react";
import { Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import freeTextLogo from "../../assets/images/free.svg";

import { DataStore } from "../../store/store";

import { sessionActions } from "../../store/action/session";

import { MutedTextColor } from "../../utils/style";

import StickyHeader from "../../components/sticky-header";

export default function Header() {
    const navigator = useNavigator();

    const me = useStore(DataStore, (s) => s.me);

    const dispatch = useDispatch(DataStore);

    const logout = async () => {
        await dispatch(sessionActions.logout());
        navigator.navigate("/");
    };

    const showRegister = ["/", ""].includes(navigator.getPath());

    return (
        <StickyHeader
            id="sticky-header"
            height={89}
            floatStyle={{
                backgroundColor: "rgba(255,255,255,0.9)",
            }}
            render={
                <div className="head div-wrapper" style={{ gap: 21 }}>
                    <div
                        style={{
                            display: "flex",
                            gap: 5,
                            alignItems: "flex-end",
                            flexWrap: "wrap",
                        }}>
                        <Link to="/" style={{ marginRight: 8 }}>
                            <img className="logo" alt="logo" src={freeTextLogo} />
                        </Link>
                        <div
                            style={{
                                fontSize: 14,
                                color: MutedTextColor,
                                whiteSpace: "pre",
                                marginBottom: -3,
                            }}>
                            <Tlt id="slogan" />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 21,
                        }}>
                        {me?.hasToken && (
                            <div>
                                <Dropdown
                                    arrow
                                    placement="bottomRight"
                                    trigger={["click"]}
                                    menu={{
                                        items: [
                                            {
                                                key: "admin",
                                                type: "item",
                                                label: getText(<Tlt template="Admin console" />),
                                                onClick: () => {
                                                    navigator.navigate("/admin");
                                                },
                                            },
                                            {
                                                key: "logout",
                                                type: "item",
                                                label: getText(<Tlt template="Sign out" />),
                                                onClick: () => logout(),
                                            },
                                        ],
                                    }}>
                                    <Button icon={<DownOutlined />} type="link">
                                        <Tlt
                                            template="Hello, {user}"
                                            data={{ user: me.info?.displayName || me.info?.email || "" }}
                                        />
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                        {showRegister && me && !me.hasToken && (
                            <>
                                <Link to="/login">
                                    <Button type="link">
                                        <Tlt template="Sign in" />
                                    </Button>
                                </Link>
                                <Link to="/signup">
                                    <Button type="primary">
                                        <Tlt template="Register account" />
                                    </Button>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            }
        />
    );
}
