import { Tlt } from "@clairejs/react";
import { useNavigator } from "@clairejs/react-web";
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import React from "react";

import navigation from "../home/nav";

const capitalize = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

const AppBreadcrumb = () => {
    const nav = useNavigator();

    const currentLocation = nav.getPath();

    const getRouteName = (pathname: string) => {
        const currentRoute = navigation.find((route) => route.to === pathname);

        return currentRoute ? currentRoute.name : "";
    };

    const getBreadcrumbs = (location: string) => {
        const breadcrumbs: { pathname?: string; name: string; active?: boolean }[] = [];

        location.split("/").reduce((prev, curr, index, array) => {
            const currentPathname = `${prev}/${curr}`;

            const routeName = getRouteName(currentPathname);

            if (routeName) {
                breadcrumbs.push({
                    pathname: currentPathname,
                    name: capitalize(routeName),
                    active: index + 1 === array.length ? true : false,
                });
            } else {
                breadcrumbs.push({
                    name: capitalize(curr),
                });
            }

            return currentPathname;
        });

        return breadcrumbs;
    };

    const breadcrumbs = getBreadcrumbs(currentLocation);

    return (
        <CBreadcrumb className="my-0">
            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <CBreadcrumbItem
                        {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
                        key={index}>
                        <Tlt exp={`${breadcrumb.name}`} />
                    </CBreadcrumbItem>
                );
            })}
        </CBreadcrumb>
    );
};

export default React.memo(AppBreadcrumb);
