import { green, orange, red, yellow } from "@ant-design/colors";

import { CashoutStatus } from "../dto/models/revenue-cashout";

export const largeTextFontSize = 30;
export const bigTextFontSize = 16;
export const smallTextFontSize = 12;

export const PrimaryColor = "#18acfc";
export const LightPrimaryColor = "#18CBFC";
export const TextColor = "#444444";
export const MutedTextColor = "#888888";
export const MutedBorderColor = "#dddddd";
export const WhiteTextColor = "#ffffff";
export const GreenColor = green[6];
export const RedColor = red[6];
export const YellowColor = yellow[6];

export const facebookLogoColor = "#1877F2";

export const getCashoutStatusColor = (status: CashoutStatus) => {
    switch (status) {
        case CashoutStatus.declined:
            return red[6];
        case CashoutStatus.executed:
            return green[6];
        case CashoutStatus.pending:
            return orange[6];
    }
};
