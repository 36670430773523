import { getText, useAPI, useStore } from "@clairejs/react";
import { CBadge, CNavLink, CSidebarNav } from "@coreui/react";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import { DataStore } from "../../store/store";
import { UserApi } from "../../store/api/user";

import { MenuItem } from "../home/nav";

interface Props {
    items: MenuItem[];
}
export const AppSidebarNav = ({ items }: Props) => {
    const me = useStore(DataStore, (s) => s.me?.info?.id);
    const api = useAPI(UserApi);

    const [isAdmin, setIsAdmin] = useState<boolean>();
    const [permissions, setPermissions] = useState<string[]>();

    const navLink = ({ name, label, icon, badge, indent }: MenuItem) => {
        return (
            <>
                {icon
                    ? icon
                    : indent && (
                          <span className="nav-icon">
                              <span className="nav-icon-bullet"></span>
                          </span>
                      )}
                {label ? getText(label) : name}
                {badge && (
                    <CBadge color={badge.color} className="ms-auto" size="sm">
                        {badge.text}
                    </CBadge>
                )}
            </>
        );
    };

    const navItem = (item: MenuItem, index: number, indent = false) => {
        const { component, name, badge, icon, label, ...rest } = item;

        const Component = component;

        return (
            <Component as="div" key={index}>
                {rest.to ? (
                    <CNavLink {...(rest.to && { as: NavLink })} {...rest}>
                        {navLink({ name, icon, badge, indent, label })}
                    </CNavLink>
                ) : (
                    navLink({ name, icon, badge, indent, label })
                )}
            </Component>
        );
    };

    const navGroup = (item, index) => {
        const { component, name, icon, items, to, ...rest } = item;
        const Component = component;
        return (
            <Component compact as="div" key={index} toggler={navLink({ name, icon })} {...rest}>
                {item.items?.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index, true)))}
            </Component>
        );
    };

    const fetchPermissions = async () => {
        const permissions = await api.getPermissions();

        setIsAdmin(permissions?.isSuperUser);
        setPermissions(permissions?.permissions.map((p) => p.permissionId));
    };

    useEffect(() => {
        if (me) {
            fetchPermissions();
        }
    }, [me]);

    return (
        <CSidebarNav as={SimpleBar}>
            {!permissions ? (
                <Spin />
            ) : (
                items
                    .filter(
                        (item) => isAdmin || !item.permissions || item.permissions.some((p) => permissions.includes(p)),
                    )
                    .map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))
            )}
        </CSidebarNav>
    );
};
