import { AbstractModel, Column, Enum, FK, HttpMethod, Model } from "@clairejs/core";

import { User } from "./user";

export enum CashoutStatus {
    pending = "pending",
    executed = "executed",
    declined = "declined",
}

@Model({
    ignoreCrud: [HttpMethod.DEL, HttpMethod.POST]
})
export class RevenueCashout extends AbstractModel {
    @Column({
        isRequired: true,
        serverValue: true,
        ...FK({ model: User }),
    })
    userId!: string;

    @Column({
        serverValue: true,
        isRequired: true,
        rangeProps: { min: 0 },
    })
    coins!: number;

    @Column({
        serverValue: true,
        isRequired: true,
    })
    currency!: string;

    @Column({
        serverValue: true,
        isRequired: true,
    })
    amount!: string;

    @Column({
        isRequired: true,
        ...Enum(CashoutStatus),
    })
    status: CashoutStatus = CashoutStatus.pending;

    @Column({})
    note?: string;
}
