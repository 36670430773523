import { Tlt, useDispatch, useStore } from "@clairejs/react";
import { cilContrast, cilMenu, cilMoon, cilSun } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
} from "@coreui/react";
import { useEffect, useRef } from "react";
import { Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";

import { useColorMode } from "../../store/hooks/ui";
import { DataStore } from "../../store/store";

import { useConfig } from "../../hooks/system";

import { AppHeaderDropdown } from "./header/index";
import { AppBreadcrumb } from "./index";

const AppHeader = () => {
    const config = useConfig();
    const headerRef = useRef(null);

    const dispatch = useDispatch(DataStore, (s) => s.ui?.sidebar);

    const sidebarVisible = useStore(DataStore, (s) => s.ui?.sidebar?.visible);

    const { mode, setMode } = useColorMode();

    useEffect(() => {
        document.addEventListener("scroll", () => {
            headerRef.current &&
                (headerRef.current as any).classList.toggle("shadow-sm", document.documentElement.scrollTop > 0);
        });
    }, []);

    return (
        <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
            <CContainer className="border-bottom px-4" fluid>
                <CHeaderToggler
                    onClick={() => dispatch({ visible: !sidebarVisible })}
                    style={{ marginInlineStart: "-14px" }}>
                    <CIcon icon={cilMenu} size="lg" />
                </CHeaderToggler>
                <CHeaderNav className="d-none d-md-flex">
                    <AppBreadcrumb />
                </CHeaderNav>
                <CHeaderNav className="ms-auto"></CHeaderNav>
                <div style={{ display: "flex", marginRight: 21 }}>
                    <Button
                        style={{ textDecoration: "none" }}
                        icon={<MessageOutlined />}
                        href={config.SUPPORT_CHAT_LINK}
                        target="_blank">
                        <Tlt template="Contact support" />
                    </Button>
                </div>

                <CHeaderNav>
                    <li className="nav-item py-1">
                        <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
                    </li>
                    <CDropdown variant="nav-item" placement="bottom-end">
                        <CDropdownToggle caret={false}>
                            {mode?.auto ? (
                                <CIcon icon={cilContrast} size="lg" />
                            ) : mode?.mode === "dark" ? (
                                <CIcon icon={cilMoon} size="lg" />
                            ) : (
                                <CIcon icon={cilSun} size="lg" />
                            )}
                        </CDropdownToggle>
                        <CDropdownMenu>
                            <CDropdownItem
                                active={!mode?.auto && mode?.mode === "light"}
                                className="d-flex align-items-center"
                                as="button"
                                type="button"
                                onClick={() => setMode("light")}>
                                <CIcon className="me-2" icon={cilSun} size="lg" /> <Tlt template="Light" />
                            </CDropdownItem>
                            <CDropdownItem
                                active={!mode?.auto && mode?.mode === "dark"}
                                className="d-flex align-items-center"
                                as="button"
                                type="button"
                                onClick={() => setMode("dark")}>
                                <CIcon className="me-2" icon={cilMoon} size="lg" /> <Tlt template="Dark" />
                            </CDropdownItem>
                            <CDropdownItem
                                active={mode?.auto}
                                className="d-flex align-items-center"
                                as="button"
                                type="button"
                                onClick={() => setMode("auto")}>
                                <CIcon className="me-2" icon={cilContrast} size="lg" /> <Tlt template="Auto" />
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                    <li className="nav-item py-1">
                        <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
                    </li>
                    <AppHeaderDropdown />
                </CHeaderNav>
            </CContainer>
        </CHeader>
    );
};

export default AppHeader;
