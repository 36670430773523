import { Outlet } from "@clairejs/react-web";

import "./index.scss";

import CustomSuspense from "../../components/custom-suspense";

import { AppContent, AppSidebar, AppFooter, AppHeader } from "../layout/index";

const DefaultLayout = () => {
    return (
        <div style={{ width: "100%" }}>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100" style={{ alignItems: "stretch" }}>
                <AppHeader />
                <div
                    className="body flex-grow-1 d-flex"
                    style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                    <AppContent>
                        <CustomSuspense>
                            <Outlet />
                        </CustomSuspense>
                    </AppContent>
                </div>
                <AppFooter />
            </div>
        </div>
    );
};

export default DefaultLayout;
