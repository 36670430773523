import { AbstractHttpClient, AbstractTokenManager } from "@clairejs/client";
import { useDispatch, useStore } from "@clairejs/react";
import { Outlet, useNavigator } from "@clairejs/react-web";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ConfigProvider, Modal, theme } from "antd";
import { useEffect } from "react";
import { initializeApp } from "firebase/app";

import { ApiClient } from "../utils/api-client";

import { sessionActions } from "../store/action/session";
import { userActions } from "../store/action/user";
import { DataStore } from "../store/store";

import { useColorMode } from "../store/hooks/ui";

import { useConfig, useInject, useLoadLanguages } from "../hooks/system";

import OtpInput from "../components/otp-input";
import CustomSuspense from "../components/custom-suspense";

const mapApiKey = "AIzaSyCjCTxRd6gqrEiodwgyQ8kv8IEEUYNbrxI";

export default function App() {
    useLoadLanguages();

    const config = useConfig();
    const navigator = useNavigator();
    const { mode } = useColorMode();
    const tokenManager = useInject(AbstractTokenManager);
    const httpClient = useInject(AbstractHttpClient) as ApiClient;

    const me = useStore(DataStore, (s) => s.me);

    const dispatch = useDispatch(DataStore);
    const dispatchUser = useDispatch(DataStore, (s) => s.me);

    useEffect(() => {
        //-- set up session expired handle
        httpClient.sessionExpiredCallback = () => {
            Modal.confirm({
                title: "Phiên làm việc hết hạn",
                content: "Bạn vui lòng đăng nhập lại",
                onOk: async () => {
                    await dispatch(sessionActions.logout());
                    navigator.navigate("/");
                },
            });
        };

        httpClient.tfaRequiredCallback = () => {
            let code = "";

            return new Promise<void>((resolve) => {
                Modal.confirm({
                    width: 600,
                    title: "Yêu cầu TFA",
                    content: (
                        <div>
                            <div style={{ textAlign: "center", marginBottom: 5 }}>
                                {"Vui lòng nhập TFA để xác thực"}
                            </div>
                            <OtpInput
                                style={{ margin: 21 }}
                                otpLength={6}
                                onChange={(otp) => {
                                    code = otp;
                                }}
                            />
                        </div>
                    ),
                    onCancel: () => resolve(),
                    onOk: async () => {
                        //-- upgrade tfa token using otp
                        try {
                            await dispatch(sessionActions.tfaUpgrade(code));
                        } finally {
                            resolve();
                        }
                    },
                });
            });
        };
    }, [dispatch, navigator]);

    useEffect(() => {
        if (me?.hasToken) {
            //-- fetch user info
            dispatchUser(userActions.fetchCurrentUser());
        }
    }, [me?.hasToken]);

    useEffect(() => {
        tokenManager.getAccessToken().then((token) => dispatchUser({ hasToken: !!token }));
    }, []);

    useEffect(() => {
        config.FIREBASE_APP && initializeApp(config.FIREBASE_APP);
    }, []);

    useEffect(() => {
        dispatch(sessionActions.fetchUserInfoFromLocal());
    }, []);

    return (
        <ConfigProvider theme={{ algorithm: mode?.mode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm }}>
            <APIProvider apiKey={mapApiKey}>
                <CustomSuspense>
                    <Outlet />
                </CustomSuspense>
            </APIProvider>
        </ConfigProvider>
    );
}
