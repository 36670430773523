import { useDispatch, useStore } from "@clairejs/react";
import { CCloseButton, CSidebar, CSidebarBrand, CSidebarFooter, CSidebarHeader, CSidebarToggler } from "@coreui/react";
import { Link } from "@clairejs/react-web";

import { DataStore } from "../../store/store";

import logo from "../../assets/logo.svg";

import navigation from "../home/nav";
import { AppSidebarNav } from "./AppSidebarNav";

export default function AppSidebar() {
    const unfoldable = useStore(DataStore, (s) => s.ui?.sidebar?.unfoldable);
    const visible = useStore(DataStore, (s) => s.ui?.sidebar?.visible);

    const dispatch = useDispatch(DataStore, (s) => s.ui?.sidebar);

    return (
        <CSidebar
            className="border-end"
            colorScheme="dark"
            position="fixed"
            unfoldable={unfoldable}
            visible={visible}
            onVisibleChange={(visible) => {
                dispatch({ visible });
            }}>
            <CSidebarHeader className="border-bottom">
                <CSidebarBrand>
                    <Link to="/">
                        <img alt="logo" src={logo} style={{ width: 32, height: 32, objectFit: "contain" }} />
                    </Link>
                </CSidebarBrand>
                <CCloseButton className="d-lg-none" dark onClick={() => dispatch({ visible: false })} />
            </CSidebarHeader>
            <AppSidebarNav items={navigation} />
            <CSidebarFooter className="border-top d-none d-lg-flex">
                <CSidebarToggler onClick={() => dispatch({ unfoldable: !unfoldable })} />
            </CSidebarFooter>
        </CSidebar>
    );
}
