import { AbstractErrorHandler, Translator } from "@clairejs/client";
import { Register } from "@clairejs/core";
import { getText, Tlt } from "@clairejs/react";
import { notification } from "antd";

export const getErrorTranslation = (error: any) => {
    //-- suppress some of the errors
    if (["TOKEN_EXPIRED", "CLIENT_TOO_OLD"].includes(error.name)) {
        return {};
    }

    if (error.name === "PRICE_UPDATED") {
        return {
            name: <Tlt id="error_PRICE_UPDATED" />,
            message: <Tlt id="error_PRICE_UPDATED_explain" />,
        };
    }

    if (error.name === "QUERY_UNIQUE_CONSTRAINT") {
        if (error.message === "referalCode") {
            return {
                name: <Tlt id="error_UNIQUE_REFERAL_CODE" />,
            };
        }
    }

    if (error.name === "DISPOSABLE_EMAIL") {
        return {
            message: <Tlt id="error_DISPOABLE_EMAIL_explain" />,
        };
    }

    if (error.name === "SUGGEST_TOO_MUCH") {
        return {
            name: <Tlt id="error_SUGGEST_TOO_MUCH" />,
        };
    }

    if (error.name === "DIRTY_LANGUAGE") {
        return {
            name: <Tlt id="error_DIRTY_LANGUAGE" />,
        };
    }

    if (error.name === "REQUEST_RATE_LIMIT") {
        return {
            name: <Tlt id="error_REQUEST_RATE_LIMIT" />,
            message: <Tlt id="error_REQUEST_RATE_LIMIT_explain" />,
        };
    }

    if (error.name === "BAD_STATE") {
        if (error.message === "Incorrect code") {
            return {
                name: <Tlt id="error_VERIFICATION_INCORRECT" />,
            };
        }

        if (error.message === "Cannot bid this search") {
            return {
                name: <Tlt id="error_BID_SEARCH" />,
                message: <Tlt id="error_BID_SEARCH_explain" />,
            };
        }

        if (error.message === "Cannot accept this bid") {
            return {
                name: <Tlt id="error_ACCEPT_SEARCH" />,
                message: <Tlt id="error_ACCEPT_SEARCH_explain" />,
            };
        }

        if (error.message === "Maximum verification exceeded") {
            return {
                name: <Tlt id="error_VERIFICATION_EXCEEDED" />,
                message: <Tlt id="error_VERIFICATION_EXCEEDED_explain" />,
            };
        }
    }

    if (error.name === "HTTP_REQUEST_ERROR") {
        return {
            name: <Tlt id="error_HTTP" />,
            message: <Tlt id="error_HTTP_explain" />,
        };
    }

    throw error;
};

@Register()
export class ErrorHandler extends AbstractErrorHandler {
    constructor(protected readonly translator: Translator) {
        super();
    }

    protected showError({ name, message }: { name?: string; message?: string }) {
        notification.error({ message: name, description: message });
    }

    protected standardizeError(err: any) {
        return err.response ? err.response.data : err.reason ? err.reason : err;
    }

    getContent(err: any) {
        const error = this.standardizeError(err);
        const { name, message } = getErrorTranslation(error);

        return { name: name && getText(name), message: message && getText(message) };
    }

    async handle(err: any) {
        try {
            const { name, message } = this.getContent(err);

            this.showError({
                name,
                message,
            });
        } catch (newErr: any) {
            const { name, message } = {
                name: <Tlt exp={newErr.name} />,
                message: newErr.message ? <Tlt exp={newErr.message} /> : <Tlt id="error_unknown" />,
            };

            this.showError({
                name: getText(name),
                message: message && getText(message),
            });
        }
    }
}
