import { useDispatch, useStore } from "@clairejs/react";
import { useColorModes } from "@coreui/react";
import { useEffect } from "react";

import { DataStore } from "../store";

const STORAGE_KEY = "COLOR_MODE";
const DEFAULT_MODE = "light";

const getAutoExplicitMode = () => {
    const now = new Date();
    return now.getHours() >= 7 && now.getHours() <= 19 ? "light" : "dark";
};

export const useColorMode = () => {
    const dispatch = useDispatch(DataStore, (s) => s.ui?.colorMode);

    const mode = useStore(DataStore, (s) => s.ui?.colorMode);

    const { setColorMode } = useColorModes("yesfree.app");

    const setMode = (mode: string) => {
        setColorMode(mode);

        const explicitMode = mode === "auto" ? getAutoExplicitMode() : (mode as "light" | "dark");
        const auto = mode === "auto";
        setColorMode(explicitMode);
        localStorage.setItem(STORAGE_KEY, mode);
        dispatch({ auto, mode: explicitMode });
    };

    useEffect(() => {
        const interval = !mode?.auto
            ? undefined
            : setInterval(() => {
                  const mode = getAutoExplicitMode();
                  setMode(mode);
              }, 60000);

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [mode?.auto]);

    useEffect(() => {
        const mode = localStorage.getItem(STORAGE_KEY);
        setMode(mode || DEFAULT_MODE);
    }, []);

    return {
        mode,
        setMode,
    };
};
