import { getText, Tlt, useAPI, useDispatch } from "@clairejs/react";
import { useNavigator } from "@clairejs/react-web";
import { Card, Form, Input, Modal } from "antd";
import { useState } from "react";

import imgFree from "../../assets/images/free.svg";

import { CreateSessionResponse, IdentityProvider } from "../../dto/http/user";

import { DataStore } from "../../store/store";
import { UserApi } from "../../store/api/user";
import { userActions } from "../../store/action/user";

import { RedColor } from "../../utils/style";

import CredentialForm from "../common/login/credential-form";
import OtpInput from "../../components/otp-input";

const Login = () => {
    const navigator = useNavigator();
    const api = useAPI(UserApi);

    const dispatchUser = useDispatch(DataStore, (s) => s.me);

    const [showPasswordReset, setShowPasswordReset] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [progress, setProgress] = useState(false);
    const [otp, setOtp] = useState("");
    const [showOtp, setShowOtp] = useState(false);
    const [otpError, setOtpError] = useState(false);

    const resetPassword = async () => {
        if (!showPasswordReset || !newPassword) {
            return;
        }

        try {
            setProgress(true);
            if (!otp) {
                await api.requestResetPassword({
                    email: showPasswordReset,
                    secret: newPassword,
                });

                setShowOtp(true);
            } else {
                try {
                    setOtpError(false);
                    await api.performResetPassword({
                        email: showPasswordReset,
                        otp,
                        secret: newPassword,
                    });

                    const response = await api.createSession({
                        provider: IdentityProvider.EMAIL,
                        data: [showPasswordReset, newPassword],
                    });

                    await handleSession(response);
                } catch (err) {
                    setOtpError(true);
                }
            }
        } finally {
            setProgress(false);
        }
    };

    const handleSession = async (response?: CreateSessionResponse & { email?: string }) => {
        if (response?.invalidCredential && response.email) {
            Modal.warn({
                title: getText(<Tlt template="Incorrect password" />),
                content: (
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                        {getText(<Tlt template="Would you like to reset your password?" />)}
                    </div>
                ),
                okCancel: true,
                cancelText: getText(<Tlt template="Try again" />),
                okText: getText(<Tlt template="Reset password" />),
                onOk: () => response.email && setShowPasswordReset(response.email),
            });
        } else if (response?.accessToken) {
            //-- fetch current user info
            const user = await dispatchUser(userActions.fetchCurrentUser());
            //--- to home screen
            navigator.navigate(user?.user.referalCode ? "/admin" : "/");
        }
    };

    return (
        <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
            <Card>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 13,
                    }}>
                    <img
                        src={imgFree}
                        style={{ height: 55, aspectRatio: 1, objectFit: "contain", margin: "35px 0" }}
                        alt="logo"
                    />
                    <h3>
                        <Tlt template="Sign in to your account" />
                    </h3>
                    <CredentialForm
                        handleResponse={handleSession}
                        submitButtonText={getText(<Tlt template="Sign in" />)}
                    />
                </div>
            </Card>
            {showPasswordReset && (
                <Modal
                    open={true}
                    title={getText(<Tlt template="Reset your password" />)}
                    maskClosable={false}
                    onCancel={() => setShowPasswordReset("")}
                    onOk={resetPassword}
                    okButtonProps={{ disabled: !newPassword, loading: progress }}>
                    <Form layout="vertical">
                        <Form.Item label={getText(<Tlt template="Email address" />)}>
                            <span style={{ fontWeight: "bold" }}>{showPasswordReset}</span>
                        </Form.Item>
                        <Form.Item label={getText(<Tlt template="New password" />)}>
                            <Input.Password disabled={showOtp} onChange={(e) => setNewPassword(e.target.value)} />
                        </Form.Item>
                        {showOtp && (
                            <Form.Item label={getText(<Tlt template="Verification code" />)}>
                                <OtpInput
                                    disabled={progress}
                                    otpLength={6}
                                    onChange={(e) => {
                                        setOtp(e);
                                        setOtpError(false);
                                    }}
                                />
                                <div style={{ color: RedColor, textAlign: "center", marginTop: 13 }}>
                                    {otpError && <Tlt template="The verification code is incorrect." />}
                                </div>
                            </Form.Item>
                        )}
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default Login;
