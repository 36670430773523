import { getInstance } from "@clairejs/core";
import { AbstractStorage, AbstractTokenManager, getAPI } from "@clairejs/client";
import { StoreAction } from "@clairejs/react";

import { UserInfo } from "../../dto/http/user";

import { DataStore } from "../store";

import { SessionApi } from "../api/session";

const USER_INFO_KEY = "USER_INFO";

export const sessionActions = {
    login:
        (username: string, password: string): StoreAction<DataStore, void> =>
        async (dispatch) => {
            const api = getAPI(SessionApi);

            const [tokenManager] = await Promise.all([getInstance(AbstractTokenManager)]);

            const response = await api.login({ username, password });

            if (response) {
                await tokenManager.setAccessToken(response.accessToken);
                dispatch({ me: { hasToken: true } });
            }
        },
    tfaUpgrade:
        (code: string): StoreAction<DataStore, void> =>
        async () => {
            const api = getAPI(SessionApi);
            const tokenManager = await getInstance(AbstractTokenManager);

            const response = await api.tfaSession(code);

            if (response) {
                await tokenManager.setAccessToken(response.accessToken);
            }
        },

    logout: (): StoreAction<DataStore, void> => async (dispatch) => {
        const [tokenManager, storage] = await Promise.all([
            getInstance(AbstractTokenManager),
            getInstance(AbstractStorage),
        ]);

        await tokenManager.setAccessToken();
        await storage.deleteItem(USER_INFO_KEY);

        dispatch({ me: { hasToken: false, info: null! } });
    },

    fetchUserInfoFromLocal: (): StoreAction<DataStore, void> => async (dispatch) => {
        const storage = await getInstance(AbstractStorage);

        const userInfo = await storage.getItem<UserInfo>(USER_INFO_KEY);

        dispatch({ me: { info: userInfo } });
    },
};
