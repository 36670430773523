import { AbstractHttpClient, AbstractTokenManager, CrudApi, stringifyQueries } from "@clairejs/client";
import { GetManyResponseBody, Register } from "@clairejs/core";

import { Payment } from "../../dto/models/payment";
import { ServiceProfile } from "../../dto/models/service-profile";
import { User } from "../../dto/models/user";

import { ConfirmPaymentRequest } from "../../dto/http/payment";
import { BanServiceProfileBody } from "../../dto/http/service";
import {
    BanUserBody,
    CreateSessionBody,
    CreateSessionResponse,
    GetCurrentUserInfoResponse,
    ResetUserPasswordBody,
    UpdateCurrentUserBody,
    UpdateUserLocationBody,
    VerifyAccountBody,
} from "../../dto/http/user";

@Register()
export class UserApi extends CrudApi<User> {
    constructor(private readonly http: AbstractHttpClient, private readonly tokenManager: AbstractTokenManager) {
        super(User, http);
    }

    async getPermissions() {
        return await this.http.get<{
            isSuperUser: boolean;
            permissions: { permissionId: string; conditions: string[] }[];
        }>("/permissions", { "cache-control": "no-cache" });
    }

    async updateCurrentUser(body: UpdateCurrentUserBody) {
        return await this.http.post<Partial<User>>("/user/current", body);
    }

    async banUser(id: string, body: BanUserBody) {
        return await this.http.post(`/user/${id}/ban`, body);
    }

    async getCurrentUserInfo() {
        return await this.http.get<GetCurrentUserInfoResponse>("/user/current", { "cache-control": "no-cache" });
    }

    async hasSession() {
        const token = await this.tokenManager.getAccessToken();
        return !!token;
    }

    async deleteAccount() {
        return await this.http.delete("/account");
    }

    async updateLocation(body: UpdateUserLocationBody) {
        return await this.http.post("/user/location", body);
    }

    async fetchFavorites() {
        return await this.http.get<GetManyResponseBody<User>>("/user/favorites");
    }

    async fetchBlocked() {
        return await this.http.get<GetManyResponseBody<User>>("/user/blockeds");
    }

    async logout() {
        return await this.tokenManager.setAccessToken();
    }

    async createSession(body: CreateSessionBody, query?: Record<string, string>) {
        const result = await this.http.post<CreateSessionResponse>(
            `/session?${stringifyQueries(query || {})}`,
            body,
            {},
            { noAuthorization: true },
        );

        if (result?.accessToken) {
            await this.tokenManager.setAccessToken(result.accessToken);
        }

        return result;
    }

    async requestResetPassword(body: ResetUserPasswordBody) {
        await this.http.post("/user/reset/request", body, {}, { noAuthorization: true });
    }

    async performResetPassword(body: VerifyAccountBody) {
        await this.http.post("/user/reset/perform", body, {}, { noAuthorization: true });
    }
}

@Register()
export class PaymentApi extends CrudApi<Payment> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(Payment, http);
    }

    async confirmPayment(body: ConfirmPaymentRequest) {
        return await this.http.post<Payment>(`/payment/confirm`, body);
    }
}

@Register()
export class ServiceProfileApi extends CrudApi<ServiceProfile> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(ServiceProfile, http);
    }

    async banProfile(id: string, body: BanServiceProfileBody) {
        await this.http.post(`/serviceprofile/${id}/ban`, body);
    }
}
