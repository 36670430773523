import { uniqueReducer } from "@clairejs/core";

export const unique = <T>(items: T[]) => items.reduce(uniqueReducer, [] as T[]);

export const readFileAsBase64 = async (file: File) => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                // convert image file to base64 string
                resolve(reader.result as string);
            },
            false,
        );

        reader.readAsDataURL(file);
    });
};

export const getPhoneNumber = (countryCode?: string, phoneNumber?: string) => {
    if (!phoneNumber) {
        return undefined;
    }

    if (!countryCode) {
        return phoneNumber;
    }
    return `(${countryCode}) ${phoneNumber}`;
};

export const fillZero = (v: number) => {
    return v < 10 ? `0${v}` : v.toString();
};

export const HHmm = (minutes: number) => {
    const hours = Math.trunc(minutes / 60);
    return `${fillZero(hours)}:${fillZero(minutes - 60 * hours)}`;
};

export const extractFileName = (fileUrl: string) => {
    const regex = /\/(([^/]+)\.(\w+))($|\?)/;
    const result = fileUrl.match(regex);
    return (result && result[1]) || "";
};

export const saveToFile = (fileName: string, fileType: string, text: string) => {
    var blob = new Blob([text], { type: fileType });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

export const resizeUrl = (url: string, width = 64) => {
    return `${url}?width=${width}`;
};

export const getGoogleMapLink = (lat: number, long: number) => {
    return `https://maps.google.com/?q=${lat},${long}`;
};

export const getFileExt = (fileName: string) => {
    const index = fileName.lastIndexOf(".");
    return index >= 0 ? fileName.substring(index + 1) : "";
};
