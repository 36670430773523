import { AccessToken } from "@clairejs/core";
import { Button } from "antd";

export interface Props {
    icon?: any;
    text?: any;
    onClick?: () => void;
    onLogin?: (err: any, result?: AccessToken) => void;
    disabled?: boolean;
    loading?: boolean;
}
export default function SocialSignupButton(props: Props) {
    return (
        <Button
            onClick={props.onClick}
            disabled={props.disabled}
            style={{ flex: 1 }}
            size="large"
            icon={props.icon}
        >
            <span>{props.text}</span>
        </Button>
    );
}
