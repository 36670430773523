import { AbstractStorage } from "@clairejs/client";
import { getServiceProvider, getSystemLocale, AbstractConstructor, Configuration } from "@clairejs/core";
import { useLanguage } from "@clairejs/react";
import { useNavigator } from "@clairejs/react-web";
import { useEffect, useRef } from "react";

import { Config } from "../config/config";

import { langs } from "../utils/constants";
import { ErrorHandler } from "../utils/error-handler";

export const useErrorHandler = () => {
    const handler = useInject(ErrorHandler);

    return handler;
};

export const useInject = <T,>(cls: AbstractConstructor<T>) => {
    const instance = useRef(getServiceProvider().getInjector().resolve(cls));
    return instance.current as T;
};

const getLocalStorage = () => getServiceProvider().getInjector().resolve(AbstractStorage);

const getPersistedLang = async () => {
    const storage = getLocalStorage();
    try {
        return await storage.getItem<string>("LANGUAGE");
    } catch (err) {
        return Object.keys(langs)[0];
    }
};

export const setPersistedLang = async (code: string) => {
    const storage = getLocalStorage();
    await storage.setItem("LANGUAGE", code);
};

export const useLoadLanguages = () => {
    const navigator = useNavigator();

    const [lang, setLang] = useLanguage();

    const setLanguage = async (code: string) => {
        setLang(code);
        await setPersistedLang(code);
    };

    useEffect(() => {
        if (!lang) {
            const queryLang = navigator.getQueries().lang;

            getPersistedLang().then((persistedLang) => {
                const userLang = queryLang || persistedLang;

                setLanguage(userLang && langs[userLang] ? userLang : getSystemLocale());
            });
        }
    }, [lang]);
};

export const useConfig = () => {
    return getServiceProvider().getInjector().resolve(Configuration) as Config;
};
