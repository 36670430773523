import { getAPI } from "@clairejs/client";

import { SystemApi } from "../api/system";
import { getFileExt } from "../../utils/data";
import { uploadFile } from "../../utils/http";

export interface PhotoData {
    id?: string;
    uploadProgress?: number;
    objectKey?: string;
    error?: boolean;
    abortUpload?: () => void;
    uri?: string;
    file?: File;
}

interface UploadOptions {
    photos: PhotoData[];
    onUpdate?: (id: string, update: Partial<Omit<PhotoData, "id">>) => void;
}
export const uploadPhotos = async ({ photos, onUpdate }: UploadOptions) => {
    const api = getAPI(SystemApi);

    const extensions = photos.map((p) => getFileExt(p.file?.name || ""));

    for (const photo of photos) {
        if (photo.id) {
            onUpdate?.(photo.id, {
                uploadProgress: 0,
            });
        }
    }

    const uploadInfo = await api.getUploadUrl(extensions);

    if (!uploadInfo) {
        return {};
    }

    const operations = () =>
        Promise.all(
            photos.map(async (photo, index) => {
                const id = photo.id;
                const file = photo.file;

                if (!id || !file) {
                    return;
                }

                const { request, upload } = uploadFile(
                    file,
                    uploadInfo.files[index].putPresignedUrl,
                    file.type || "application/octet-stream",
                    (progress) => {
                        onUpdate?.(id, {
                            uploadProgress: progress,
                        });
                    },
                );

                onUpdate?.(id, {
                    abortUpload: () => request.abort(),
                });

                await upload();

                onUpdate?.(id, {
                    uploadProgress: 1,
                    objectKey: uploadInfo.files[index].objectKey,
                });
            }),
        );

    return { operations };
};
