import { FacebookAuthProvider, getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { IonIcon } from "@ionic/react";
import { logoFacebook } from "ionicons/icons";
import { Tlt } from "@clairejs/react";

import { facebookLogoColor } from "../../../utils/style";

import LoginButton, { Props as ButtonProps } from "./login-button";

export interface Props extends Omit<ButtonProps, "icon" | "text"> {}
export default function FacebookLoginButton({ onLogin, ...props }: Props) {
    const login = async () => {
        const provider = new FacebookAuthProvider();

        provider.addScope("public_profile");
        provider.addScope("email");

        const auth = getAuth();

        try {
            const result = await signInWithPopup(auth, provider);
            const credential = OAuthProvider.credentialFromResult(result);

            if (!credential?.idToken) {
                throw new Error("NO_TOKEN");
            }

            onLogin?.(null, {
                token: credential.idToken,
                expiration: Date.now(),
            });
        } catch (err) {
            //-- authentication error, ignore
        }
    };

    return (
        <LoginButton
            {...props}
            onClick={login}
            text={<Tlt template="Use Facebook" />}
            icon={<IonIcon icon={logoFacebook} style={{ color: facebookLogoColor }} />}
        />
    );
}
