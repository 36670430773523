import { Config } from "./config";

const config: Config = {
    API_SERVER_URL: "https://api.staging.alovendor.tamidi.com",
    STRIPE_DASHBOARD_URL: "https://dashboard.stripe.com/test",
    WEB_SIGNUP_LINK: "https://staging.yesfree.app/signup",
    FIREBASE_APP: {
        apiKey: "AIzaSyDZg_-e_F6mLFjhh96PEZVjJ3gOeXqgjf4",
        authDomain: "staging-alovendor.firebaseapp.com",
        projectId: "staging-alovendor",
        storageBucket: "staging-alovendor.firebasestorage.app",
        messagingSenderId: "481692062326",
        appId: "1:481692062326:web:68329273b8b6a41ddcc557",
        measurementId: "G-BDL2C3R6MN"
    },
    SUPPORT_CHAT_LINK: "https://m.me/316878261515934",
};

export default config;
