export const uploadFile = (
    file: File,
    destinationUrl: string,
    contentType: string,
    onProgress: (progress: number) => void,
) => {
    const request = new XMLHttpRequest();

    const upload = () =>
        new Promise<void>((resolve, reject) => {
            request.upload.onprogress = (e) => {
                onProgress(e.loaded / e.total);
            };

            request.open("put", destinationUrl);
            request.setRequestHeader("Content-Type", contentType);

            request.send(file);

            request.onreadystatechange = function () {
                if (request.readyState === XMLHttpRequest.DONE) {
                    resolve();
                }
            };

            request.onerror = (err) => reject(err);
        });

    return { request, upload };
};
