import { AbstractModel, Column, DataType, FK, Index, Model } from "@clairejs/core";

import { User } from "./user";

@Model()
@Index([["title", 1]])
@Index([["content", 1]])
@Index([["targetUsers", 1]])
@Index([["viewedBy", 1]])
@Index([["countries", 1]])
export class SystemAnnounce extends AbstractModel {
    @Column({
        description: "Title of announce",
        isRequired: true,
        searchable: {},
    })
    title!: string;

    @Column({
        description: "Content of announce",
        isRequired: true,
        searchable: {},
    })
    content!: string;

    titleLocale?: Record<string, string>;

    contentLocale?: Record<string, string>;

    @Column({
        description: "Id of author user",
        isRequired: true,
        ...FK({ model: User }),
    })
    createdBy!: string;

    @Column({
        description: "The countries that this announce is available, null for all",
        vectorProps: { elementDataType: DataType.STRING },
        isRequired: true,
    })
    countries!: string[];

    @Column({
        description: "Id of targetted users, null for all users",
        vectorProps: { elementDataType: DataType.STRING },
        isRequired: true,
        ...FK({ model: User }),
    })
    targetUsers!: string[];

    @Column({
        description: "Id of users who viewed this announce",
        vectorProps: { elementDataType: DataType.STRING },
        ...FK({ model: User }),
    })
    viewedBy?: string[];

    @Column({})
    published?: boolean;
}
