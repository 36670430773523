import { diffData } from "@clairejs/core";
import { getText, Tlt, useDispatch, useStore } from "@clairejs/react";
import { Avatar, Form, Input, InputRef, Modal } from "antd";
import { useRef, useState } from "react";

import { User } from "../../../dto/models/user";

import { userActions } from "../../../store/action/user";
import { PhotoData, uploadPhotos } from "../../../store/hooks/upload";
import { DataStore } from "../../../store/store";

interface Props {
    onClose?: (success?: boolean) => void;
}
export default function ProfileSettings({ onClose }: Props) {
    const me = useStore(DataStore, (s) => s.me?.info);
    const uploadInputRef = useRef<InputRef>(null);
    const [progress, setProgress] = useState(false);

    const dispatch = useDispatch(DataStore, (s) => s.me?.info);

    const [selectedPhoto, setSelectedPhoto] = useState<PhotoData>({
        uri: me?.avatar,
    });
    const [displayName, setDisplayName] = useState(me?.displayName);

    const uploadPhoto = async (file?: File) => {
        if (!file) return;

        setSelectedPhoto((photo) => ({ ...photo, uploadProgress: 0, file, uri: URL.createObjectURL(file) }));

        //-- upload
        const { operations } = await uploadPhotos({
            photos: [{ file, id: "1" }],
            onUpdate: (_, update) => {
                setSelectedPhoto((photo) => ({ ...photo, ...update }));
            },
        });

        await operations?.();
    };

    const update = async () => {
        try {
            setProgress(true);

            const data: Partial<User> = { displayName, avatar: selectedPhoto.objectKey || selectedPhoto.uri };

            const diff = diffData(me, data);

            if (diff && Object.entries(diff).length) {
                await dispatch(userActions.updateCurrentUser(diff));
            }

            onClose?.(true);
        } finally {
            setProgress(false);
        }
    };

    return (
        <Modal
            title={getText(<Tlt template="Update my profile" />)}
            open={true}
            onOk={update}
            okButtonProps={{ loading: progress }}
            onCancel={() => onClose?.()}
            maskClosable={false}>
            <Form layout="vertical">
                <Form.Item label={getText(<Tlt template="Profile avatar" />)}>
                    <Input
                        ref={uploadInputRef}
                        style={{ width: 0, height: 0, padding: 0, visibility: "hidden" }}
                        type="file"
                        onChange={(e) => uploadPhoto(e.target.files?.[0])}
                    />
                    <Avatar
                        size={120}
                        src={selectedPhoto.uri}
                        onClick={() => uploadInputRef.current?.nativeElement?.click()}
                    />
                </Form.Item>
                <Form.Item label={getText(<Tlt template="Display name" />)}>
                    <Input defaultValue={me?.displayName} onChange={(e) => setDisplayName(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
    );
}
