import { useLanguage } from "@clairejs/react";
import { IonIcon } from "@ionic/react";
import { Button, Dropdown } from "antd";
import { globe } from "ionicons/icons";
import styled from "styled-components";

import { langs } from "../utils/constants";

import { setPersistedLang } from "../hooks/system";

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default function LanguageSwitcher() {
    const [lang, setLang] = useLanguage();

    return (
        <Wrapper>
            {lang && (
                <Dropdown
                    placement="bottomLeft"
                    menu={{
                        items: Object.entries(langs).map(([code, text]) => ({
                            label: text.long,
                            key: code,
                            type: "item",
                            onClick: () => {
                                setPersistedLang(code).then(() => setLang(code));
                            },
                        })),
                    }}
                    trigger={["click"]}>
                    <Button type="default">
                        <IonIcon icon={globe}></IonIcon>
                        <span
                            style={{
                                fontFamily: "monospace",
                                fontSize: 14,
                            }}>
                            {langs[lang].short}
                        </span>
                    </Button>
                </Dropdown>
            )}
        </Wrapper>
    );
}
