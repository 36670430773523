import { AbstractHttpClient, CrudApi } from "@clairejs/client";
import { Register } from "@clairejs/core";

import { SystemAnnounce } from "../../dto/models/system-announce";
import { UserVoice } from "../../dto/models/user-voice";

import { SyncRemoteConfigResponse, SystemSettingTemplate } from "../../dto/http/system";

interface UploadUrlResponse {
    objectKey: string;
    putPresignedUrl: string;
    deletePresignedUrl: string;
}

export type UploadHandlerFn = (ext?: string) => Promise<UploadUrlResponse>;

export interface GetUploadUrlResponseBody {
    files: {
        objectKey: string;
        putPresignedUrl: string;
        deletePresignedUrl: string;
    }[];
}

@Register()
export class SystemApi {
    constructor(protected readonly http: AbstractHttpClient) {}

    async getSystemSettings() {
        return await this.http.get<SystemSettingTemplate>("/systemsetting");
    }

    async updateSystemSettings(settings: SystemSettingTemplate) {
        await this.http.put("/systemsetting", settings);
    }

    async syncFirebase() {
        return await this.http.post<SyncRemoteConfigResponse>("/remoteconfig", {});
    }

    async getUploadUrl(ext: string[]) {
        const result = await this.http.get<GetUploadUrlResponseBody>(
            `/upload/presigned?extensions=${JSON.stringify(ext)}`,
            {
                "cache-control": "no-cache",
            },
        );
        return result;
    }
}

@Register()
export class SystemAnnounceApi extends CrudApi<SystemAnnounce> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(SystemAnnounce, http);
    }
}

@Register()
export class UserVoiceApi extends CrudApi<UserVoice> {
    constructor(protected readonly http: AbstractHttpClient) {
        super(UserVoice, http);
    }

    async markRead(voiceId: string) {
        return await this.http.put<{ userId: string }>(`/uservoice/${voiceId}/read`, {});
    }
}
