import { getAPI, mergeInstances } from "@clairejs/client";
import { ModelStore, StoreAction, getCrudActions } from "@clairejs/react";

import { Payment } from "../../dto/models/payment";
import { ServiceProfile } from "../../dto/models/service-profile";
import { User } from "../../dto/models/user";

import { ConfirmPaymentRequest } from "../../dto/http/payment";
import { BanServiceProfileBody } from "../../dto/http/service";
import { BanUserBody, GetCurrentUserInfoResponse, UpdateCurrentUserBody, UserInfo } from "../../dto/http/user";

import { DataStore } from "../store";

import { PaymentApi, ServiceProfileApi, UserApi } from "../api/user";

export const userActions = {
    ...getCrudActions(UserApi),
    updateCurrentUser:
        (body: UpdateCurrentUserBody): StoreAction<UserInfo> =>
        async (dispatch) => {
            const api = getAPI(UserApi);

            const response = await api.updateCurrentUser(body);

            dispatch({ ...body, ...response });
        },
    banUser:
        (id: string, body: BanUserBody): StoreAction<ModelStore<User>> =>
        async (dispatch, store) => {
            const api = getAPI(UserApi);

            await api.banUser(id, body);

            const instances = store()?.instances || [];

            await dispatch({
                instances: mergeInstances(User, instances, [{ id, ...body }]),
            });
        },
    fetchCurrentUser: (): StoreAction<DataStore["me"], GetCurrentUserInfoResponse | undefined> => async (dispatch) => {
        const api = getAPI(UserApi);

        const userInfo = await api.getCurrentUserInfo();

        if (userInfo) {
            dispatch({ info: userInfo.user });
        }

        return userInfo;
    },
};

export const paymentActions = {
    ...getCrudActions(PaymentApi),
    confirmPayment:
        (body: ConfirmPaymentRequest): StoreAction<ModelStore<Payment>> =>
        async (dispatch, store) => {
            const api = getAPI(PaymentApi);

            await api.confirmPayment(body);

            const instances = store()?.instances || [];

            await dispatch({
                instances: mergeInstances(Payment, instances, [body]),
            });
        },
};

export const serviceProfileActions = {
    ...getCrudActions(ServiceProfileApi),

    banProfile:
        (id: string, body: BanServiceProfileBody): StoreAction<ModelStore<ServiceProfile>> =>
        async (dispatch, store) => {
            const api = getAPI(ServiceProfileApi);

            await api.banProfile(id, body);

            const instances = store()?.instances || [];

            await dispatch({
                instances: mergeInstances(ServiceProfile, instances, [{ id, ...body }]),
            });
        },
};
