import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { IonIcon } from "@ionic/react";
import { logoApple } from "ionicons/icons";
import { Tlt } from "@clairejs/react";

import LoginButton, { Props as ButtonProps } from "./login-button";

interface Props extends Omit<ButtonProps, "icon" | "text"> {
    disabled?: boolean;
    loading?: boolean;
}

export default function AppleLoginButton({ onLogin, ...props }: Props) {
    const login = async () => {
        const provider = new OAuthProvider("apple.com");
        provider.addScope("name");
        provider.addScope("email");

        const auth = getAuth();

        try {
            const result = await signInWithPopup(auth, provider);
            const credential = OAuthProvider.credentialFromResult(result);

            if (!credential?.idToken) {
                throw new Error("NO_TOKEN");
            }

            onLogin?.(null, {
                token: credential.idToken,
                expiration: Date.now(),
            });
        } catch (err) {
            //-- authentication error, ignore
        }
    };

    return (
        <LoginButton
            {...props}
            onClick={login}
            icon={<IonIcon icon={logoApple} style={{ color: "#000" }} />}
            text={<Tlt template="Use Apple ID" />}
        />
    );
}
